import React from "react";
import "./AdviserCard.css";

const AdviserCard = ({ photo, name, position, location }) => {
  return (
    <div className="card-container">
      <div className="image-container">
        <img src={photo} alt={name} className="card-image" />
      </div>
      <div className="text-container">
        <h3 className="card-name">{name}</h3>
        <p className="card-position">{position}</p>
        {location && <p className="card-location">{location}</p>}
      </div>
    </div>
  );
};

export default AdviserCard;
