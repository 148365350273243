import React, { useState } from "react";
import { Form, Input, Select, Button, Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { submitJobApplicationForm } from '../../services/service';
import "./Job.css"; // Optional for additional custom styles

const { Option } = Select;

const JobApplicationForm = () => {
    const [form] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onFinish = async (values) => {
        const payload = new FormData();
    
        Object.keys(values).forEach((key) => {
            if (key === "resume") {
                // Append the file object directly for upload
                payload.append(key, values.resume.file.originFileObj);
            } else {
                payload.append(key, values[key]);
            }
        });
    
        setIsSubmitting(true); // To indicate submission in progress
        try {
            const response = await submitJobApplicationForm(payload);
            if(response.success){
                message.success('Application submitted successfully!');
                form.resetFields();
            }
        } catch (error) {
            message.error('Failed to submit application.');
            console.error('There was an error sending the email!', error);
        } finally {
            setIsSubmitting(false); // Reset submission state
        }
    };
    

    return (
        <div className="form-container">
            <h2>Apply for a jdfhvbndfmkvfdjbhv Job</h2>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{ position: "", phoneCountryCode: "+91" }}
            >
                <Form.Item
                    label="Full Name"
                    name="fullName"
                    rules={[{ required: true, message: "Please enter your full name!" }]}
                    required
                >
                    <Input placeholder="Enter your full name" />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            pattern: /^[a-zA-Z0-9._%+-]+@gmail\.com$/,
                            message: "Email must be a valid Gmail address (e.g., example@gmail.com)",
                        },
                    ]}
                    validateTrigger="onChange" // Trigger validation on each input change
                    required
                >
                    <Input placeholder="Enter your email" />
                </Form.Item>

                <Form.Item
                    label="Phone"
                    name="phone"
                    required
                >
                    <Input.Group compact>
                        <Form.Item
                            name="phoneCountryCode"
                            noStyle
                            initialValue="+91"
                            rules={[{ required: true, message: "Please select your country code!" }]}
                            required
                        >
                            <Select style={{ width: 100 }} placeholder="Country Code">
                                <Option value="+1">+1 (USA)</Option>
                                <Option value="+91">+91 (India)</Option>
                                <Option value="+44">+44 (UK)</Option>
                                <Option value="+61">+61 (Australia)</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            noStyle
                            rules={[
                                {
                                    required: true,
                                    pattern: /^[0-9]{10}$/,
                                    message: "Phone number must be exactly 10 digits",
                                },
                            ]}
                            required
                        >
                            <Input
                                style={{ width: "calc(100% - 100px)" }}
                                placeholder="Enter your phone number"
                                maxLength={10}
                                onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>

                <Form.Item
                    label="Position"
                    name="position"
                    rules={[{ required: true, message: "Please select a position!" }]}
                    required
                >
                    <Select placeholder="Select a position">
                        <Option value="Frontend Developer">Frontend Developer</Option>
                        <Option value="Frontend Developer">Frontend Developer</Option>
                        <Option value="Frontend Developer">Frontend Developer</Option>
                        <Option value="Frontend Developer">Frontend Developer</Option>
                        <Option value="Frontend Developer">Frontend Developer</Option>
                        <Option value="Backend Developer">Backend Developer</Option>
                        <Option value="Fullstack Developer">Fullstack Developer</Option>
                        <Option value="Business Development (BA)">
                            Business Development (BA)
                        </Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Year of Experience"
                    name="experience"
                    rules={[
                        {
                            required: true,
                            validator: (_, value) => {
                                if (value === undefined || value === "") {
                                    return Promise.reject("Please enter your years of experience!");
                                }
                                const numberValue = Number(value);
                                if (isNaN(numberValue) || numberValue < 0) {
                                    return Promise.reject("Experience must be 0 or more!");
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                    validateTrigger="onChange"
                    required
                >
                    <Input
                        type="number"
                        placeholder="Enter your years of experience"
                        min="0"
                    />
                </Form.Item>

                <Form.Item
                    label="Resume"
                    name="resume"
                    valuePropName="file"
                    rules={[{ required: true, message: "Please upload your resume!" }]}
                    required
                >
                    <Upload.Dragger
                        name="resume"
                        accept=".pdf,.doc,.docx"
                        maxCount={1}
                        beforeUpload={() => false}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            Click or drag file to this area to upload
                        </p>
                        <p className="ant-upload-hint">
                            Only PDF, DOC, or DOCX files are supported.
                        </p>
                    </Upload.Dragger>
                </Form.Item>

                <Form.Item label="Cover Letter" name="coverLetter">
                    <Input.TextArea
                        rows={4}
                        placeholder="Write your cover letter (optional)"
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isSubmitting}
                        block
                    >
                        {isSubmitting ? "Submitting..." : "Submit Application"}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default JobApplicationForm;
