import React from 'react';
import './ItServices.css'; // Import the CSS file for styling
import AiImage from '../../images/AiImage.jpg';
import AppImage from '../../images/AppImages.jpg';
import WebImage from '../../images/webimage.jpg';

const ITServices = () => {
  const services = [
    {
      id: 'app-development',
      title: 'App Development',
      summary: 
        "We specialize in crafting high-quality, user-friendly mobile and web applications tailored to your specific needs. " +
        "Our team of expert developers utilizes the latest technologies to build engaging and intuitive user experiences. " + 
        "Whether you need a simple mobile app or a complex enterprise solution, we can help you bring your vision to life.",
      backgroundImage: AppImage,
    },
    {
      id: 'web-development',
      title: 'Web Development',
      summary: 
        "From simple websites to complex e-commerce platforms, we provide robust and scalable web solutions. " +
        "Our expertise spans a wide range of technologies, including front-end and back-end development, ensuring optimal performance and security. " +
        "We focus on creating user-centered designs that are both visually appealing and easy to navigate.",
      backgroundImage: WebImage,
    },
    {
      id: 'ai-model-solutions',
      title: 'AI Model Solutions',
      summary: 
        "We leverage the power of AI to develop innovative solutions, including machine learning models and AI-powered applications. " +
        "Our team of AI/ML experts can help you analyze data, automate processes, and gain valuable insights. " +
        "We specialize in developing custom AI models tailored to your specific business needs, driving efficiency and growth.",
      backgroundImage: AiImage,
    },
  ];     

  return (
    <section className="it-services-container">
 <h2 style={{ 
          background: 'linear-gradient(to right, #00ff00, #0000ff)', 
          WebkitBackgroundClip: 'text', 
          WebkitTextFillColor: 'transparent',
          color: 'transparent', 
        }}>
        <span style={{ 
          background: 'linear-gradient(to left, #ff6666, #1a0000)', 
          WebkitBackgroundClip: 'text', 
          WebkitTextFillColor: 'transparent' 
        }}>Our IT Services</span>
      </h2>
      <div className="service-cards">
        {services.map((service) => (
          <div key={service.id} className="service-card">
            <h3>{service.title}</h3>
            <img src={service.backgroundImage} alt={service.title} className="service-card-image" />
            <p style={{ fontFamily: 'Ramaraja' }}>{service.summary}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ITServices;