import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavigationBar.css';
import logo from '../../images/CompanyLogo.png';
import PhoneToggle from '../PhoneToggle/PhoneToggle';

const NavigationBar = ({ activeLink }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className='nav-container'>
      <div className='header_image'>
        <div className='nev-logo'>
          <img src={logo} alt="Logo" />
        </div>
        <div className="navbar-toggle" onClick={toggleMenu}>
          {menuOpen ? '✖' : '☰'}
        </div>
      </div>
      {menuOpen && <PhoneToggle activeLink={activeLink} toggleMenu={toggleMenu} />}
      {!menuOpen && (
        <nav className="navbar">
          <ul className={`navbar-nav ${menuOpen ? 'open' : ''}`}>
            <li className={`nav-item ${activeLink === '/' ? 'active' : ''}`}>
              <Link to="/" className="nav-link">Home</Link>
            </li>
            <li className={`nav-item ${activeLink === '/gallery' ? 'active' : ''}`}>
              <Link to="/gallery" className="nav-link">Gallery</Link>
            </li>
            <li className={`nav-item ${activeLink === '/ourAdvisers' ? 'active' : ''}`}>
              <Link to="/ourAdvisers" className="nav-link">Our Advisers</Link>
            </li>

            <li className={`nav-item ${activeLink === '/about' ? 'active' : ''}`}>
              <Link to="/about" className="nav-link">About</Link>
            </li>
            <li className={`nav-item ${activeLink === '/services' ? 'active' : ''}`}>
              <Link to="/services" className="nav-link">Services</Link>
            </li>
            <li className={`nav-item ${activeLink === '/itservices' ? 'active' : ''}`}>
              <Link to="/itservices" className="nav-link">IT Services</Link>
            </li>
            <li className={`nav-item ${activeLink === '/contact' ? 'active' : ''}`}>
              <Link to="/contact" className="nav-link">Contact</Link>
            </li>
            {/* <li className={`nav-item ${activeLink === '/job' ? 'active' : ''}`}>
              <Link to="/job" className="nav-link">Job</Link>
            </li> */}
          </ul>
        </nav>
      )}
    </div>
  );
};

export default NavigationBar;
