import React, { useState } from 'react';
import './Contact.css';
import mobileLogo from '../../images/mobile.png';
import emailLogo from '../../images/email.png';
import whatsappLogo from '../../images/whatsapp.png';
import { sendEmail } from '../../services/service';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await sendEmail(formData);
      setStatus('Email sent successfully!');
    } catch (error) {
      setStatus('Failed to send email.');
      console.error('There was an error sending the email!', error);
    }

    setFormData({
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: ''
    });
  };

  const handleWhatsAppClick = () => {
    const phoneNumber = '9863636969';
    const message = 'Hello, I would like to contact you regarding...';
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  const handleEmailClick = () => {
    const email = 'support@technocomconsultants.com';
    window.location.href = `mailto:${email}`;
  };

  const handlePhoneClick = () => {
    const phone = '91- 98 6363 6969';
    window.location.href = `tel:${phone}`;
  };

  return (
    <div>
      <div className="contact-form">
        <h2>Contact Us</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Subject:</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit" className="submit-button"> Send Request </button>
        </form>
        {status && <p>{status}</p>}
      </div>
      <div className="container">
        <div className="box" onClick={handlePhoneClick} style={{ cursor: 'pointer' }}>
          <img src={mobileLogo} alt="Mobile" className="icon" />
          <p>+91- 9863636969</p>
        </div>
        <div className="box" onClick={handleEmailClick} style={{ cursor: 'pointer' }}>
          <img src={emailLogo} alt="Email" className="icon" />
          <p>Support@technocomconsultants.com</p>
        </div>
        <div className="box" onClick={handleWhatsAppClick} style={{ cursor: 'pointer' }}>
          <img src={whatsappLogo} alt="WhatsApp" className="icon" />
          <p>+91 9863636969</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
