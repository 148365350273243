import React, { useEffect, useRef } from 'react';
import './Gallery.css';

// Manually import each image
import image1 from '../../GallleryImages/Image1.jpeg';
import image2 from '../../GallleryImages/Image2.jpeg';
import image3 from '../../GallleryImages/image3.jpeg';
import image4 from '../../GallleryImages/image4.jpeg';
import image5 from '../../GallleryImages/image5.jpeg';
import image6 from '../../GallleryImages/image6.jpeg';
import image7 from '../../GallleryImages/image7.jpeg';
import image8 from '../../GallleryImages/image8.jpeg';
import image9 from '../../GallleryImages/image9.jpeg';
import image10 from '../../GallleryImages/image10.jpeg';
import image11 from '../../GallleryImages/image11.jpeg';
import image12 from '../../GallleryImages/image12.jpeg';
import image13 from '../../GallleryImages/image13.jpeg';
import video1 from '../../GallleryImages/video1.mp4';
import video2 from '../../GallleryImages/video2.mp4';
import video3 from '../../GallleryImages/video3.mp4';
import video4 from '../../GallleryImages/video4.mp4';

const videos = [video1, video2,video3,video4]; // Separate array for videos

const images = [
  image1, image3, image5, image6, image7,
  image9, image10, image4, image12, image13,
  image11, image8
];

const Gallery = () => {
  const videoRefs = useRef([]);

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the container
      rootMargin: '0px',
      threshold: 0.5 // Play video when 50% of it is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const video = entry.target;
        if (entry.isIntersecting) {
          video.play(); // Play the video when it is visible
        } else {
          video.pause(); // Pause the video when it is not visible
        }
      });
    }, options);

    videoRefs.current.forEach(video => {
      if (video) {
        observer.observe(video);
      }
    });

    // Cleanup the observer on component unmount
    return () => {
      videoRefs.current.forEach(video => {
        if (video) {
          observer.unobserve(video);
        }
      });
    };
  }, []);

  return (
    <div className="gallery-container">
      <h1 className="gallery-header">Gallery</h1>
      <div className="image-grid">
        {images.map((image, index) => (
          <div className="image-item" key={index}>
            <img src={image} alt={`Gallery image ${index + 1}`} />
          </div>
        ))}
        {videos.map((video, index) => (
          <div className="image-item" key={`video-${index}`}>
            <video 
              ref={el => (videoRefs.current[index] = el)} 
              width="100%" 
              controls 
              muted // Mute to allow autoplay in some browsers
            >
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;


