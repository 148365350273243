import React from "react";
import AdviserCard from "../AdviserCard/AdviserCard";
import "./OurAdvisers.css"; // Import the CSS file
import Images from '../../images/unknownMan.jpg'

// Sample data for advisers
const advisers = [
    {
        photo: Images,
        name: "",
        position: "",
        location: "Raipur",
      },{
        photo: Images,
        name: "",
        position: "",
        location: "Delhi",
      },{
        photo: Images,
        name: "",
        position: "",
        location: "Pune",
      },{
        photo: Images,
        name: "",
        position: "",
        location: "Siliguri",
      },{
        photo: Images,
        name: "",
        position: "",
        location: "Gurugram",
      }
];

const OurAdvisers = () => {
  return (
    <div className="advisers-container">
      <h2 className="advisers-title">Our Advisers</h2>
      <h2 className="advisers-title">List Comming Soon .......</h2>
      <div className="grid-container">
        {advisers.map((adviser, index) => (
          <AdviserCard
            key={index}
            photo={adviser.photo}
            name={adviser.name}
            position={adviser.position}
            location={adviser.location}
          />
        ))}
      </div>
    </div>
  );
};

export default OurAdvisers;
